import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModifierComponent } from '../modifier/modifier.component';
import { UseCartComponent } from '../use-cart/use-cart.component';
import { Item, ItemStatus } from '../../services/api.service';
import { CartLine, CartService } from '../../services/cart.service';
import { ScheduleService } from '../../services/schedule.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
    standalone: false
})
export class ItemComponent implements OnInit, OnDestroy {
  @Input()
  public item: Item;

  public quantity: number;
  public readonly: boolean;
  public unavailable: boolean;

  private cartSubscrition: Subscription;
  private scheduleSubscription: Subscription;
  private originalPrice: number;

  constructor(
    private cartService: CartService,
    private scheduleService: ScheduleService,
    private translateService: TranslateService,
    private dataService: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  public ngOnInit(): void {
    this.quantity = this.getQuantityFromCart();
    this.originalPrice = this.item.price;
    this.readonly = this.isReadOnly();
    this.unavailable = this.item.status === ItemStatus.Unavailable;

    this.cartSubscrition = this.cartService.emptyEmitter
      .subscribe(isEmpty => {
        if (isEmpty) {
          this.quantity = 0;
        } else {
          // TODO: this is super dirty
          const lines: CartLine[] = this.cartService.getCart().cartLines.filter(cartLine => cartLine.item.id === this.item.id);

          this.quantity = lines.reduce((accumulator, line) => accumulator + line.quantity, 0);
        }
      });

    this.scheduleSubscription = this.scheduleService
      .schedule$
      .subscribe(schedule => {
        this.updateItem(schedule);
      });

    this.scheduleService.checkScheduleFromItems();
  }

  public ngOnDestroy(): void {
    this.cartSubscrition.unsubscribe();
    this.scheduleSubscription.unsubscribe();
  }

  public increase(): void {
    if (this.item.status === ItemStatus.Enabled && !this.isReadOnly()) {
      this.openModifierDialog();
    } else if (this.item.status === ItemStatus.Unavailable && !this.isReadOnly()) {
      this.snackBar.open(
        this.translateService.instant('COMMON.ITEM_UNAVAILABLE'),
        this.translateService.instant('CART.GOT_IT'),
        {
          duration: 3500,
          panelClass: 'custom-snackbar'
        }
      );
    }
  }

  public decrease(): void {
    if (this.item.modifier_groups.length === 0 && this.quantity > 0) {
      this.cartService.updateCart(this.item, [], -1);
    } else {
      this.openUseCartDialog();
    }
  }

  private openModifierDialog(): void {
    // TODO: wrap this into a service
    const directionClass: string = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
    const dialogClass: string = !this.item.image_url
      && this.item.modifier_groups.length === 0 ? 'modifier-container-small' : 'modifier-container';

    const dialogRef = this.dialog.open(ModifierComponent, {
      disableClose: true,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: [dialogClass, directionClass],
      data: {
        item: this.item
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.cartService.updateCart(this.item, data.selectedMods, data.quantity);
      } else {
        console.log('Clicked on cancel !');
      }
    });
  }

  private openUseCartDialog(): void {
    this.dialog.open(UseCartComponent, {
      disableClose: true
    });
  }

  private updateItem(schedule): void {
    if (schedule.item === undefined) {
      return;
    }

    if (this.item.name !== schedule.item.name) {
      return;
    }

    if (schedule.isActive) {
      this.item.price = schedule.schedule.price;
    } else {
      this.item.price = this.originalPrice;
    }
  }

  private getQuantityFromCart(): number {
    let num = 0;
    this.cartService.getCart().cartLines.filter(l => l.item.id === this.item.id).forEach(l => num += l.quantity);
    return num;
  }

  private isReadOnly(): boolean {
    return this.item.status === ItemStatus.ReadOnly;
  }


}
