import { Component, OnDestroy, OnInit } from '@angular/core';
import { Table, Restaurant, VenueType, ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MenuService } from '../menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { debounceTime } from 'rxjs/operators';
import { FiltersService } from '../../services/filters.service';
import { DialogService } from '../../services/dialog.service';
import { ThemeSourcePipe } from '../../shared/pipes/theme-source.pipe';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: false
})
export class MenuComponent implements OnDestroy,  OnInit {

  public currentRestaurant: Restaurant;
  public venueType = VenueType;
  public currentTable: Table;
  public currentSectionName: string;
  public searchOpened = false;
  public filtersActive = false;
  public description: string;
  public hasMultipleLanguages = false;
  public hasMultipleCurrencies = false;
  public hasFilters = false;
  public hasWallpaper = false;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public wallpaperStyle: any;

  private tableSubscription = Subscription.EMPTY;
  private restaurantSubscription = Subscription.EMPTY;
  private menuSearchSubscription = Subscription.EMPTY;
  private descriptionSub = Subscription.EMPTY;
  private filtersSub = Subscription.EMPTY;
  private langSub = Subscription.EMPTY;
  private currencySub = Subscription.EMPTY;
  private hasFiltersSub = Subscription.EMPTY;

  constructor(
    public menuService: MenuService,
    private dataService: DataService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dialogService: DialogService,
    private filtersService: FiltersService,
    private themeSourcePipe: ThemeSourcePipe,
    private apiService: ApiService
  ) { }

  public ngOnInit(): void {
    this.route.params
      .subscribe(params => {
        this.currentSectionName = decodeURIComponent(params['section']);
        this.menuService.setCurrentSectionByName(this.currentSectionName);
      });

    this.langSub = this.dataService.hasMultiLanguage$
      .subscribe(multi => {
        this.hasMultipleLanguages = multi;
      });

    this.currencySub = this.dataService.hasMultiCurrency$
      .subscribe(multi => {
        this.hasMultipleCurrencies = multi;
      });

    this.hasFiltersSub = this.dataService.hasFilters$
      .subscribe(filters => {
        this.hasFilters = filters;
      });

    this.descriptionSub = this.menuService.description$
      .subscribe(desc => {
        this.description = desc;
      });

    this.currentRestaurant = this.dataService.getRestaurant();

    this.tableSubscription = this.dataService.tableEmitter
      .subscribe(table => {
        this.currentTable = table;
    });

    this.restaurantSubscription = this.dataService.restaurantEmitter
      .subscribe(restaurant => {
        this.currentRestaurant = restaurant;
    });

    this.menuSearchSubscription = this.menuService.searchForm.controls.search.valueChanges
      .pipe(debounceTime(300))
        .subscribe(v => {
          if (v) {
            this.menuService.applyGlobalSearch(v.trim());
          }
    });

    this.filtersSub = this.filtersService.isActive$
      .subscribe(isActive => {
        this.filtersActive = isActive;
      });

    this.getWallpaper();
  }

  public ngOnDestroy(): void {
    this.tableSubscription.unsubscribe();
    this.restaurantSubscription.unsubscribe();
    this.menuSearchSubscription.unsubscribe();
    this.descriptionSub.unsubscribe();
    this.filtersSub.unsubscribe();
    this.langSub.unsubscribe();
    this.currencySub.unsubscribe();
    this.hasFiltersSub.unsubscribe();
  }

  public openFilters(): void {
    this.dialogService.openFilters();
  }

  public openLanguages(): void {
    this.dialogService.openLanguages();
  }

  public toggleSearch(): void {
    this.searchOpened = ! this.searchOpened;
    if (this.searchOpened) {
      this.menuService.searchForm.controls.search.setValue('');
      // TODO: Dirty way of focusing the input
      const searchElement = window.document.getElementById('search-input');
      if (searchElement) {
        searchElement.focus();
      }
      this.currentSectionName = this.translateService.instant('MENU.SEARCH');
      this.router.navigate(['menu/search']);
      this.menuService.searchOpened.emit(true);
    } else {
      this.closeSearch();
    }
  }

  public closeSearch(): void {
    this.searchOpened = false;
    this.menuService.searchOpened.emit(false);
    this.location.back();
  }

  public getBanner(): string {
    return this.menuService.getSuperSectionBanner() !== '' ? this.menuService.getSuperSectionBanner() : this.currentRestaurant.banner_url;
  }

  public getWallpaper(): void {
    const wallpaperURL = this.themeSourcePipe.transform('wallpaper.png');

    const img = new Image();
    img.onload = () => {
      this.hasWallpaper = true;
      this.wallpaperStyle = {
        background: `url('${wallpaperURL}')`,
        'background-size': 'cover',
        '-webkit-background-size': 'cover',
        '-moz-background-size': 'cover',
        '-o-background-size': 'cover',
      };
    //  Remove background color when wallpaper is present
    document.body.style.background = 'none';
    };
    img.onerror = () => {
      console.clear();
      return;
    };
    img.src = wallpaperURL;
  }
}
