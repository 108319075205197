/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, NgZone, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  ApiService,
  GetOrder,
  PaymentType,
  NotificationPayload,
  NotificationType,
  OrderStatus,
  PaymentMethod, PaymentMode, VoucherPayload, AppliedVoucher, VoucherMode, Fee
} from '../services/api.service';
import { DataService } from '../services/data.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { ChargeToMyRoomComponent } from './charge-to-my-room/charge-to-my-room.component';
import { Location } from '@angular/common';
import { OnlinePaymentService } from '../services/online-payment.service';
import { ApplePayConfig } from '../cart/cart.component';
import { ApplePayService } from '../services/apple-pay.service';
import { OrderService } from '../services/order.service';
import { Router } from '@angular/router';
import { VoucherModalComponent } from '../cart/voucher-modal/voucher-modal.component';

@Component({
    selector: 'app-bill',
    templateUrl: './bill.component.html',
    styleUrls: ['./bill.component.scss'],
    standalone: false
})
export class BillComponent implements OnInit {

  public paymentType: PaymentMethod | undefined;
  public paymentMethods: PaymentMethod[];
  public orders: GetOrder[];
  public hasDiscount: boolean;
  public submitted = false;
  public paymentMode: PaymentMode;
  public PaymentMode = PaymentMode;
  public showPayButton = true;
  public isApplePay: boolean;
  public isLoading = false;
  public appliedVoucher: AppliedVoucher;
  public isOrderFree = false;
  public VoucherMode = VoucherMode;

  public fees: Fee[];
  public subtotal: number;
  public finalTotal: number;
  public discountAmount: number;
  public feesAmount: number;

  public paidSubtotal: number;
  public paidFinalTotal: number;
  public paidDiscountAmount: number;
  public paidFeesAmount: number;
  public paidFees: Fee[];

  // Orders to be counted in the total payment calculation
  public billedOrders: GetOrder[];
  public paidOrders: GetOrder[];
  private voucherName: string;
  private transactionId: string | undefined;
  private hasFees = false;

  constructor(
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private translateService: TranslateService,
    private apiService: ApiService,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private location: Location,
    private onlinePaymentService: OnlinePaymentService,
    private applePayService: ApplePayService,
    private orderService: OrderService,
    private ngZone: NgZone,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.hasDiscount = false;
    const userId: string = this.dataService.getUserId();
    const restaurantId: string = this.dataService.getRestaurant()?.id;
    this.paymentMode = this.dataService.getRestaurant()?.payment_mode;

    this.dataService.hasFees$
      .subscribe(hasFees => {
        this.hasFees = hasFees;
      });

    if (!userId || !restaurantId) {
      this.router.navigate(['/menu']);
      return;
    } else {
      this.apiService.getOrders(userId, restaurantId).subscribe(orders => {
        // type != pre
        if (this.paymentMode !== PaymentMode.Prepaid) {
          this.orders = orders.orders;

          // Piad Orders info (previous orders)
          this.paidOrders = orders.orders.filter(order => order.status === OrderStatus.Paid);
          this.paidSubtotal = this.paidOrders.reduce((accumulator, order) => accumulator + order.total_price_without_discount, 0);
          this.paidDiscountAmount = this.paidOrders.reduce((accumulator, order) => accumulator + order.discount_amount, 0);
          this.paidFeesAmount = this.paidOrders.reduce((accumulator, order) => accumulator + order.fee_amount, 0);
          this.paidFinalTotal = this.paidOrders.reduce((accumulator, order) => accumulator + order.total_price, 0);
          const paidFees = this.paidOrders.find(order => order.fees && order.fees?.length > 0)?.fees;
          this.paidFees = paidFees || [];

          // Current orders
          this.billedOrders = orders.orders.filter(order => order.status === OrderStatus.Unpaid);

          if (this.billedOrders.length > 0) {
            this.applyPostpaidCheckout(this.voucherName);
          }

        // Prepaid:
        } else {
          this.orders = orders.orders.filter(order => order.status === OrderStatus.Paid);
          this.paidOrders = this.orders;
          this.paidSubtotal = this.orders.reduce((accumulator, order) => accumulator + order.total_price_without_discount, 0);
          this.paidDiscountAmount = this.orders.reduce((accumulator, order) => accumulator + order.discount_amount, 0);
          this.paidFeesAmount = this.orders.reduce((accumulator, order) => accumulator + order.fee_amount, 0);
          this.paidFinalTotal = this.orders.reduce((accumulator, order) => accumulator + order.total_price, 0);
          this.paidFees = this.orders[0]?.fees || [];
        }
      });

      let paymentMethods = this.dataService.getPaymentMethods();
      if (paymentMethods?.length > 0) {
        if (!this.applePayService.isApplePayAvailable()) {
          paymentMethods = paymentMethods.filter((type) => type.key !== 'applepay');
        }
        this.paymentType = paymentMethods[0];
        if (this.paymentType.key === 'applepay') {
          this.isApplePay = true;
        } else {
          this.isApplePay = false;
        }
        this.paymentMethods = paymentMethods;
      } else {
        this.showPayButton = false;
      }

      this.onlinePaymentService.paymentCancelled.subscribe(cancelled => {
        if (cancelled) {
          // angular can't detect changes made by async callbacks of third-party libraries because "they are not in angular's zone".
          // which is in our case the checkout lightbox https://angular.io/guide/zone
          this.ngZone.run( () => {
            this.isLoading = false;
            this.showPayButton = true;
          });
        }
      });
    }
  }

  public submit(): void {
    if (!this.voucherName && !this.hasFees) {
      this.confirm();
      return;
    }

    const restaurantId: string = this.dataService.getRestaurant()?.id;
    this.apiService.confirmPostpaidCheckout(restaurantId, this.getVoucherPayload()).subscribe(res => {
      this.transactionId = res.transaction_id;
      this.confirm();
    });
  }

  public confirm(): void {
    if (this.paymentType?.key === PaymentType.Room) {
      this.openChargeToMyRoom();
      return;
    }

    if (this.paymentType?.is_online && this.paymentType?.key !== 'applepay' && !this.isOrderFree) {
      this.isLoading = true;
      this.showPayButton = false;
      const orderIds = this.billedOrders.map(order => order.order_id);
      this.onlinePaymentService.startPayment(orderIds);
    } else {
      this.sendNotification();
    }
  }

  public openPaymentTypeModal(): void {
    const bottomSheetRef = this.bottomSheet.open(PaymentMethodComponent, {
      panelClass: 'payment-method-container',
      disableClose: true,
      data: {
        paymentType: this.paymentType?.key,
        paymentMethods: this.paymentMethods
      },
    });
    bottomSheetRef.afterDismissed().subscribe(ev => {
      this.paymentType = this.paymentMethods.find((pm) => pm.key === ev.paymentType) || {} as PaymentMethod;
      if (this.paymentType.key === 'applepay') {
        this.isApplePay = true;
      } else {
        this.isApplePay = false;
      }
    });
  }

  public submitApplePay(): void {
    const applePaySession = new (window as any).ApplePaySession(6, this.getApplePayConfig());
    const orderIds = this.billedOrders.map(order => order.order_id);
    this.applePayService.startApplePaySession(applePaySession, orderIds);

    this.applePayService.ApplePaySuccess.subscribe(state => {
      if (state) {
        const transactionId = this.applePayService.getTransactionId();
        this.orderService.setLastTransaction(transactionId);
      }
    });
  }

  public openVoucherModal(): void {
    const dialogRef = this.dialog.open(VoucherModalComponent, {
      width: '350px',
      data: {
        postpaid: true,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.applyPostpaidCheckout(data.voucherName);
      }
    });
  }

  private openChargeToMyRoom(): void {
    const dialogRef = this.dialog.open(ChargeToMyRoomComponent, {
      disableClose: true,
      width: '350px'
    });
    dialogRef.afterClosed().subscribe(roomDetails => {
      this.sendNotification(roomDetails);
    });
  }
  private makeBillNotification(roomDetails?: any): NotificationPayload {
    const payload: NotificationPayload = {
      type: NotificationType.BringTheBill,
      payment_type: this.paymentType?.key as PaymentType,
      table: this.dataService.getTable().id,
      user: this.dataService.getUserId(),
      has_discount: this.hasDiscount,
      voucher_name: this.voucherName
    };

    if (roomDetails) {
      payload.name = roomDetails.name;
      payload.room_number = roomDetails.roomNumber;
    }

    return payload;
  }

  private sendNotification(roomDetails?: any): void {
    this.submitted = true;

    if (this.isOrderFree) {
      if (this.transactionId) {
        this.orderService.setLastTransaction(this.transactionId);
      }
      return;
    }

    const notification: NotificationPayload = this.makeBillNotification(roomDetails);
    // abort if no payment type available.
    if (!notification.payment_type) {
      return;
    }
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    this.apiService.sendNotification(notification).subscribe(ok => {
      this.snackBar.open(
        this.translateService.instant('BILL.SNACKBAR_TITLE'),
        this.translateService.instant('BILL.SNACKBAR_OK'),
        {
          duration: 2500,
          panelClass: 'custom-snackbar'
        }
      );
    });
    this.location.back();
  }

  private getApplePayConfig(): ApplePayConfig {
    const total = this.finalTotal;
    const currency = this.dataService.getRestaurant().currency;
    return {
      countryCode: currency === 'SAR' ? 'SA' : 'AE',
      currencyCode: currency,
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover', 'mada'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: 'Opaala',
        amount: total
      }
    };
  }

  private applyPostpaidCheckout(voucherName?: string): void {
    this.voucherName = voucherName || '';
    const restaurantId: string = this.dataService.getRestaurant()?.id;
    this.apiService.applyPostpaidCheckout(restaurantId, this.getVoucherPayload())
      .subscribe(res => {
        this.appliedVoucher = res.voucher;
        this.fees = res.orders[0].fees || [];

        this.subtotal = res.orders.reduce((accumulator, order) => accumulator + order.total_price_without_discount, 0);
        this.finalTotal = res.orders.reduce((accumulator, order) => accumulator + order.total_price, 0);
        this.discountAmount = res.orders.reduce((accumulator, order) => accumulator + order.discount_amount, 0);
        this.feesAmount = res.orders[0].fee_amount;


        if (this.appliedVoucher.mode === VoucherMode.Balance && this.finalTotal <= 0) {
          this.dataService.setVoucherName(this.voucherName);
        }

        if (this.finalTotal <= 0) {
          this.isOrderFree = true;
          this.paymentType = undefined;
        }
      });
  }

  private getVoucherPayload(): VoucherPayload {
    return {
      table_id: this.dataService.getTable().id,
      voucher_name: this.voucherName,
      user_id: this.dataService.getUserId(),
      order_ids: this.billedOrders.map(order => order.order_id),
      payment_type: this.paymentType?.key
    };
  }
 }
