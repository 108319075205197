import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-charge-to-my-room',
    templateUrl: './charge-to-my-room.component.html',
    styleUrls: ['./charge-to-my-room.component.scss'],
    standalone: false
})
export class ChargeToMyRoomComponent implements OnInit {

  public formGroup: FormGroup;
  public name: string;
  public roomNumber: string;

  constructor(
    public dialogRef: MatDialogRef<ChargeToMyRoomComponent>
  ) { }

  public ngOnInit(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.name, [Validators.required, Validators.minLength(2)]),
      roomNumber: new FormControl(this.roomNumber, [Validators.required, Validators.minLength(2)]),
    });
  }

  public confirm(): void {
    if (! this.isFormValid()) {
      return;
    }

    this.dialogRef.close({
      name: this.getName().value,
      roomNumber: this.getRoomNumber().value
    });
  }

  private getName(): FormControl {
    return this.formGroup.get('name') as FormControl;
  }

  private getRoomNumber(): FormControl {
    return this.formGroup.get('roomNumber') as FormControl;
  }

  private isFormValid(): boolean {
    return this.getName().valid && this.getRoomNumber().valid;
  }
}
