import { Component } from '@angular/core';

@Component({
    selector: 'app-payment-failed',
    templateUrl: './payment-failed.component.html',
    styleUrls: ['./payment-failed.component.scss'],
    standalone: false
})
export class PaymentFailedComponent {



}
