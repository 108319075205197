import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CurrencyService } from '../services/currency.service';
import { StorageService, StoredItem } from '../services/storage.service';

export interface Language {
  label: string;
  key: string;
  selected: boolean;
}

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: false
})
export class SettingsComponent implements OnInit, OnDestroy {

  public previousLang;
  public currentLang;
  public languages: Language[];
  public currencies: string[];
  public previousCurrency: string;
  public selectedCurrency: string;
  public confirmDisabled = true;

  private currencySub = Subscription.EMPTY;

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private currencyService: CurrencyService,
    public dialogRef: MatDialogRef<SettingsComponent>,
  ) {
    this.languages = this.translateService.getLangs().map(x => ({
      label: `LANGUAGES.${x.toUpperCase()}`,
      key: x,
      selected: this.currentLang === x
    }));
    this.previousLang = this.translateService.currentLang;
    this.currentLang = this.translateService.currentLang;
  }

  public ngOnInit(): void {
    this.currencyService.setCurrencies();
    this.currencySub = this.currencyService.currencies$
      .subscribe(response => {
        this.currencies = response;
      });

    this.selectedCurrency = this.currencyService.currentCurrency;
    this.previousCurrency = this.selectedCurrency;
  }

  public ngOnDestroy(): void {
    this.currencySub.unsubscribe();
  }

  public updateLang(): void {
    this.confirmDisabled = false;
    this.translateService.use(this.currentLang);
    this.storageService.set(StoredItem.Language, this.currentLang);
    if (this.currentLang === 'ar') {
      this.dialogRef.addPanelClass('rtl');
    } else {
      this.dialogRef.removePanelClass('rtl');
      this.dialogRef.addPanelClass('ltr');
    }
  }

  public updateCurrency(): void {
    this.confirmDisabled = false;
    this.currencyService.setCurrentCurrency(this.selectedCurrency);
  }

  public close(): void {
    if (this.currentLang !== this.previousLang) {
      this.currentLang = this.previousLang;
      this.updateLang();
    }
    if (this.selectedCurrency !== this.previousCurrency) {
      this.selectedCurrency = this.previousCurrency;
      this.updateCurrency();
    }
    this.dialogRef.close();
  }

  public confirm(): void {
    this.dialogRef.close();
  }
}
