import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ActivableFilter, FiltersService } from '../../services/filters.service';


@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: false
})
export class FiltersComponent implements OnInit, OnDestroy {
  public filters: ActivableFilter[];

  private filtersSub = Subscription.EMPTY;

  constructor(
    private filtersService: FiltersService,
    public dialogRef: MatDialogRef<FiltersComponent>,
    ) { }

  public ngOnInit(): void {
    this.filtersService.setFilters();
    this.getFilters();
  }

  public ngOnDestroy(): void {
    this.filtersSub.unsubscribe();
  }

  public getFilters(): void {
    this.filtersSub = this.filtersService.allFilters$
      .subscribe(filters => {
        this.filters = filters.map(filter => ({...filter}));
      });
  }

  public confirm(): void {
    this.filtersService.updateFilters(this.filters);
    this.filtersService.setActiveFilters();
    this.close();
  }

  public clearSelections(): void {
    this.filters.forEach(filter => {
      filter.isActive = false;
    });
    this.filtersService.updateFilters(this.filters);
    this.filtersService.setActiveFilters();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public changeSelection(checked: boolean, filter: ActivableFilter): void {
    const index = this.filters.indexOf(filter);
    this.filters[index].isActive = checked;
  }
}
