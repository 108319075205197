import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Transaction } from '../services/api.service';
import { OrderService } from '../services/order.service';

@Component({
    selector: 'app-bill-paid',
    templateUrl: './bill-paid.component.html',
    styleUrls: ['./bill-paid.component.scss'],
    standalone: false
})
export class BillPaidComponent {

  public transaction: Transaction;
  public total: number;

  constructor(
    private orderService: OrderService,
    private router: Router,
  ) {
    this.transaction = this.orderService.getLastTransaction();

    if (!this.transaction) {
      this.close();
    }

    this.total = this.transaction.orders.reduce((accumulator, order) => accumulator + order.total_price, 0);
  }

  public close(): void {
    this.router.navigate(['/menu', {openMenu: true}]);
  }

  public openReceipt(): void {
    const url = `${environment.urls.api}/receipt/transaction/${this.transaction.transaction.id}.pdf`;
    window.open(url, '_blank');
  }


}
