import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../../services/data.service';
import { SwiperOptions } from 'swiper';
import { MenuService } from '../menu.service';
import { SuperSectionService } from '../../services/super-section.service';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../../services/cart.service';
import { Item } from '../../services/api.service';
import { ModifierComponent } from '../modifier/modifier.component';
import { MatDialog } from '@angular/material/dialog';
import { SwiperDirective } from '../../shared/directives/swiper.directive';
import { CommonModule } from '@angular/common';


export interface Promotion {
  image: string;
  item: {
    id: string;
  };
  menu_section: {
    id: string;
  };
}

@Component({
    selector: 'app-promotions',
    templateUrl: './promotions.component.html',
    styleUrls: ['./promotions.component.scss'],
    imports: [
        CommonModule,
        SwiperDirective
    ],
    encapsulation: ViewEncapsulation.None,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PromotionsComponent implements OnInit {

  public promotions: Promotion[];
  public config: SwiperOptions = {
    loop: true,
    autoplay: {
      disableOnInteraction: false
    },
    resizeObserver: true,
    spaceBetween: 30
  };

  constructor(
    private dataService: DataService,
    private menuService: MenuService,
    private superSectionService: SuperSectionService,
    private translateService: TranslateService,
    private cartService: CartService,
    private dialog: MatDialog,
  ) {  }

  public ngOnInit(): void {
    this.promotions = this.dataService.getPromotions();
  }

  public openPromotion(promo: Promotion): void {
    if (promo.menu_section?.id) {
      this.menuService.setCurrentSectionById(promo.menu_section.id);
      this.superSectionService.menuEmitter.emit(false);
    } else if (promo.item?.id) {
      this.openModifierDialog(promo.item?.id);
    }
  }

  private openModifierDialog(id: string): void {
    const item: Item = this.menuService.getItemById(id);

    const directionClass: string = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
    const dialogClass: string = !item.image_url
      && item.modifier_groups.length === 0 ? 'modifier-container-small' : 'modifier-container';

    const dialogRef = this.dialog.open(ModifierComponent, {
      disableClose: true,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: [dialogClass, directionClass],
      data: {
        item
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.cartService.updateCart(item, data.selectedMods, data.quantity);
      }
    });
  }

}
