import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../services/data.service';
import { StorageService, StoredItem } from '../services/storage.service';
import { SuperSectionService } from '../services/super-section.service';
import { Language } from '../settings/settings.component';

@Component({
    selector: 'app-language-selection',
    templateUrl: './language-selection.component.html',
    styleUrls: ['./language-selection.component.scss'],
    standalone: false
})
export class LanguageSelectionComponent implements OnInit {

  public venueName: string;
  public languages: Language[];
  public currentLang;

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private superSectionService: SuperSectionService,
    private router: Router,
    private dataService: DataService
  ) {
    this.currentLang = this.translateService.currentLang;
  }

  public ngOnInit(): void {
    this.languages = this.translateService.getLangs().map(x => ({
      label: `LANGUAGES.${x.toUpperCase()}`,
      key: x,
      selected: this.currentLang === x
    }));

    this.venueName = this.dataService.getRestaurant()?.name;
  }

  public selectLanguage(lang: string): void {
    this.currentLang = lang;
    this.translateService.use(this.currentLang);
    this.storageService.set(StoredItem.Language, this.currentLang);
    this.router.navigate(['/menu', {openMenu: true}]);
    this.superSectionService.openMenu();
  }

}
