import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Section, Item } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { FiltersService } from '../../services/filters.service';

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss'],
    standalone: false
})
export class SectionComponent implements OnDestroy, OnChanges {

  @Input()
  public section: Section;
  public items: Item[];

  public isActive: boolean;

  private menuSearchSubscription = Subscription.EMPTY;
  private filterSubscription = Subscription.EMPTY;

  constructor(
    private filtersService: FiltersService,
  ) {
    this.isActive = true;
  }

  public ngOnDestroy(): void {
    this.menuSearchSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
  }

  public ngOnChanges(): void {
    this.items = this.section.items;
    this.filterSubscription = this.filtersService.activeFilters$
    .subscribe(filters => {
      this.items = this.applyFilters(filters);
    });
  }

  private applyFilters(filters: string[]): Item[] {
    const items = this.section.items;
    const filteredItems: Item[] = [];

    if (filters.length === 0) {
      return items;
    } else {
      items.forEach(item => {
        if (filters.every(f => item.filters.includes(f))) {
          filteredItems.push(item);
        }
      });
      return filteredItems;
    }
  }

}
