import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService, NotificationPayload, NotificationType, WaiterOption } from '../services/api.service';
import { DataService } from '../services/data.service';

@Component({
    selector: 'app-waiter',
    templateUrl: './waiter.component.html',
    styleUrls: ['./waiter.component.scss'],
    standalone: false
})
export class WaiterComponent implements OnInit {

  public options: WaiterOption[];
  public selectedOption: string;
  public comment: string;
  public loaded = false;

  constructor(
    public dialogRef: MatDialogRef<WaiterComponent>,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private translateService: TranslateService,
    private apiService: ApiService
  ) { }

  public ngOnInit(): void {
    this.comment = '';
    const currentRestaurant = this.dataService.getRestaurant();
    this.getWaiterOptions(currentRestaurant.id);
  }

  public confirm(confirm: boolean): void {
    if (! confirm) {
      this.dialogRef.close();
      return;
    }

    const notification: NotificationPayload = this.makeWaiterNotification();
    this.apiService.sendNotification(notification).subscribe(() => {
      this.snackBar.open(
        this.translateService.instant('WAITER.SNACKBAR_TITLE'),
        this.translateService.instant('WAITER.SNACKBAR_OK'),
        {
          duration: 2500,
          panelClass: 'custom-snackbar'
        }
      );
    });
    this.dialogRef.close();
  }

  private makeWaiterNotification(): NotificationPayload {
    return {
      type: NotificationType.CallWaiter,
      table: this.dataService.getTable().id,
      user: this.dataService.getUserId(),
      waiter_option_id: this.selectedOption,
      comment: this.comment
    };
  }

  private getWaiterOptions(restaurantId: string) {
    this.apiService.getWaiterOptions(restaurantId)
      .subscribe(response => {
        this.loaded = true;
        if (response.length > 0) {
          const defaultOption: WaiterOption = {
            id: '',
            key: '',
            name: '-',
            lang: ''
          };
          this.options = response;
          this.options.unshift(defaultOption);
          this.selectedOption = this.options[0].id;
        }
    });
  }
}
