import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, concat, concatMap, delay, map, Observable, of, skip, Subscription, tap } from 'rxjs';
import { ApiService, GetOrder } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { DataService } from '../services/data.service';
import { DialogService } from '../services/dialog.service';
import { OnlinePaymentService } from '../services/online-payment.service';
import { OrderService } from '../services/order.service';

export interface EzeTapStatusResponse {
  status: string;
}

export enum EzeTapStatus {
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Failure = 'FAILURE'
}

@Component({
    selector: 'app-ezetap',
    templateUrl: './ezetap.component.html',
    styleUrls: ['./ezetap.component.scss'],
    standalone: false
})
export class EzetapComponent implements OnInit, OnDestroy {

  private transactionId: string;
  private order: GetOrder;
  private pollingInterval: Subscription;
  private polledStatus$: Observable<EzeTapStatusResponse>;
  private load$ = new BehaviorSubject('');
  private isTxnDone = false;
  private machineId: string;

  constructor(
    public dialogRef: MatDialogRef<EzetapComponent>,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA)  public data: any,
    private orderService: OrderService,
    private cartService: CartService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private onlinePaymentService: OnlinePaymentService,
    private dataService: DataService
  ) { }

  public ngOnInit(): void {
    this.transactionId = this.data.transaction_id;
    this.machineId = this.dataService.getEzetapMachineId();
    this.order = this.data.orders[0];
    this.pollStatus();
  }

  public ngOnDestroy(): void {
    if (!this.isTxnDone) {
      this.apiService.cancelEzetapPayment(this.machineId, this.transactionId).subscribe();
    }
    this.pollingInterval.unsubscribe();
  }

  private pollStatus(): void {
    // polling the status api, basically an api call is fired when the previous one is completed + 3 seconds.
    // Whenever a value passes status$, we know that the request is completed.
    const status$ = this.apiService.getEzetapStatus(this.machineId, this.transactionId);

    /* I will probably forget what this code does later so here is an explanation:
    We created a stream using the static 'of'. This will fire an event immediately when subscribed to.
    We then delay this event with 1 second by using the delay operator.
    We then use a tap where we can actually trigger the next request,
    and finally skip since we do not want to use the '' event anywhere,
    it was just a trigger.*/

    const whenToRefresh$ = of('').pipe(
      delay(3000),
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      tap(_ => this.load$.next('')),
      skip(1),
    );

    const poll$ = concat(status$, whenToRefresh$);
    this.polledStatus$ = this.load$.pipe(
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      concatMap(_ => poll$),
      map((response: EzeTapStatusResponse) => response),
   );

   this.handlePollResult();
  }

  private handlePollResult(): void {
    this.pollingInterval = this.polledStatus$.subscribe(res => {
      if (res.status !== EzeTapStatus.Pending) {
        this.isTxnDone = true;
        this.handleTxnResult(res.status);
      }
    });
  }

  private handleTxnResult(result: string) {
    this.pollingInterval.unsubscribe();

    if (result === EzeTapStatus.Success) {
      this.orderService.setLastOrder(this.order);
      this.cartService.clearCart();
      this.dialogService.closeDialogs();
    } else {
      this.onlinePaymentService.paymentCancelled.emit(true);
      this.dialogRef.close();
      this.dialogService.openPaymentFailed();
    }
  }
}
