import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Table, VenueType, Menu, Restaurant } from '../../services/api.service';
import { SuperSectionService } from '../../services/super-section.service';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { MenuService } from '../menu.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-super-sections-list',
    templateUrl: './super-sections-list.component.html',
    styleUrls: ['./super-sections-list.component.scss'],
    standalone: false
})
export class SuperSectionsListComponent implements OnDestroy, OnInit {
  @Input() menuOpened = true;

  public menu: Menu;
  public currentTable: Table;
  public superSections: (string | undefined)[];
  public venueTypes = VenueType;
  public currentRestaurant: Restaurant;

  private superSectionSubscription = Subscription.EMPTY;
  private restaurantSubscription = Subscription.EMPTY;
  private tableSubscription = Subscription.EMPTY;


  constructor(
    private superSectionService: SuperSectionService,
    private dataService: DataService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.currentTable = this.dataService.getTable();
    this.currentRestaurant = this.dataService.getRestaurant();

    if (!this.superSections) {
      this.menuService.displayableSuperSections$
        .subscribe(names => {
          this.superSections = names.map(section => section?.name);
        });
    }

    this.restaurantSubscription = this.dataService
      .restaurantEmitter
      .subscribe(restaurant => {
        this.currentRestaurant = restaurant;
    });

    this.tableSubscription = this.dataService
      .tableEmitter
      .subscribe(table => {
      this.currentTable = table;
    });

    this.superSectionSubscription = this.superSectionService
      .menuEmitter
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      .subscribe(ok => {
        this.menuOpened = true;
      });
  }

  public ngOnDestroy(): void {
    this.superSectionSubscription.unsubscribe();
    this.restaurantSubscription.unsubscribe();
    this.tableSubscription.unsubscribe();
  }

  public select(section?: string): void {
    if (section) {
      this.router.navigate(['/menu', encodeURIComponent(section)]);
    } else if (!section && !this.route.snapshot.params['section']) {
      this.menuService.setCurrentSectionByName();
    }
    this.menuOpened = false;
  }

}
