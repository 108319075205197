import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../services/dialog.service';
import { CartService } from '../services/cart.service';

@Component({
    selector: 'app-payment-link-confirmation',
    templateUrl: './payment-link-confirmation.component.html',
    styleUrls: ['./payment-link-confirmation.component.scss'],
    standalone: false
})
export class PaymentLinkConfirmationComponent {
  public constructor(
    private router: Router,
    private dialogService: DialogService,
    private cartService: CartService
  ){}
  public close(): void {
    this.cartService.clearCart();
    this.dialogService.closeDialogs();
    this.router.navigate(['/menu', {openMenu: true}]);
  }
}
