/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Item, Modifier, ModifierGroup, VenueType } from '../../services/api.service';
import { DataService } from '../../services/data.service';

export interface InputData {
  item: Item;
}

export interface SelectedModifier {
  id: string;
  quantity: number;
}

@Component({
    selector: 'app-modifier',
    templateUrl: './modifier.component.html',
    styleUrls: ['./modifier.component.scss'],
    standalone: false
})
export class ModifierComponent implements OnInit {

  public itemName: string;
  public description: string;
  public models: any;
  public total: number;
  public submitted = false;
  public quantity = 1;
  public readOnly = true;
  public fullScreenImg = false;

  constructor(
    public dialogRef: MatDialogRef<ModifierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dataService: DataService
  ) {
    this.description = data.item.description;
    this.itemName = data.item.name;
    this.setIsReadOnly();
  }

  public ngOnInit(): void {
    this.models = {};
    for (const modifierGroup of this.data.item.modifier_groups) {
      modifierGroup.is_valid = true;
      if (modifierGroup.type === 'single' || modifierGroup.type === 'single-compulsory') {
        if (modifierGroup.display_type === 'dropdown') {
          const defaultOption: Modifier = {
            id: '',
            name: '-',
            price: 0,
            quantity: 0
          };
          if (!this.findModifierById('')) {
            modifierGroup.modifiers.unshift(defaultOption);
          }
          this.models[modifierGroup.id] = defaultOption.id;
          modifierGroup.is_valid = false;

        } else {
          if (modifierGroup.type === 'single-compulsory') {
            modifierGroup.is_valid = false;
          }
          this.models[modifierGroup.id] = null;
        }
      } else if (modifierGroup.type === 'multiple' && !modifierGroup.type_max) {
        this.models[modifierGroup.id] = {};
        for (const modifier of modifierGroup.modifiers) {
          this.models[modifierGroup.id][modifier.id] = false;
        }
      } else if (modifierGroup.type === 'multiple' && modifierGroup.type_max) {
        this.models[modifierGroup.id] = {};
        modifierGroup.is_valid = false;
        for (const modifier of modifierGroup.modifiers) {
          this.models[modifierGroup.id][modifier.id] = 0;
        }
      } else if (modifierGroup.type === 'min-max') {
        this.models[modifierGroup.id] = {};
        if (modifierGroup.type_min && modifierGroup.type_min >= 1) {
          modifierGroup.is_valid = false;
        }
      } else {
        console.error('Unknow modifier group type');
        console.error(modifierGroup);
      }
    }
    this.calculateTotal();
  }

  /**
   * This method is used for the modifier type 'single', as you can
   * choose 0 or 1 modifier. This gives you the possibility to deselect
   * a modifier you already selected.
   */
  public deselect(modifierGroupId: string, modifierId: string): void {
    const current: string | null = this.models[modifierGroupId];

    if (current === modifierId) {
      setTimeout(() => {
        this.models[modifierGroupId] = null;
      }, 200);
    }

    this.onChange();
  }

  public check(modifierGroup: ModifierGroup): void {
    const numberOfChoices = this.getNumberOfChoices(modifierGroup);
    const min: number = modifierGroup.type_min || 0;
    const max: number = modifierGroup.type_max || 1;

    if (numberOfChoices === min) {
      modifierGroup.is_valid = true;
    }

    if (numberOfChoices < min) {
      modifierGroup.is_valid = false;
    } else if (numberOfChoices > max) {
      let extra: number = max - numberOfChoices;
      for (const modKey of Object.keys(this.models[modifierGroup.id])) {
        const modValue: boolean = this.models[modifierGroup.id][modKey];
        if (modValue && extra !== 0) {
          setTimeout(() => {
            this.models[modifierGroup.id][modKey] = false;
          }, 200);
          extra++;
        }
      }
    }

    this.onChange();
  }

  public incrementModifier(modifierGroup: ModifierGroup, modifierId: string): void {
    const max: number = modifierGroup.type_max || 1;
    let numberOfChoices = 0;
    for (const modKey of Object.keys(this.models[modifierGroup.id])) {
      const modValue: number = this.models[modifierGroup.id][modKey];
      numberOfChoices += modValue;
    }
    if (numberOfChoices >= max) {
      return;
    }
    this.models[modifierGroup.id][modifierId] += 1;

    if ((modifierGroup.type_min || modifierGroup.type_min === 0) && numberOfChoices + 1 >= modifierGroup.type_min) {
      modifierGroup.is_valid = true;
    } else {
      modifierGroup.is_valid = false;
    }
    this.onChange();
  }

  public decrementModifier(modifierGroup: ModifierGroup, modifierId: string): void {
    let numberOfChoices = 0;
    for (const modKey of Object.keys(this.models[modifierGroup.id])) {
      const modValue: number = this.models[modifierGroup.id][modKey];
      numberOfChoices += modValue;
    }
    if (numberOfChoices <= 0) {
      return;
    }
    this.models[modifierGroup.id][modifierId] -= 1;

    if ((modifierGroup.type_min || modifierGroup.type_min === 0) && numberOfChoices - 1 >= modifierGroup.type_min) {
      modifierGroup.is_valid = true;
    } else {
      modifierGroup.is_valid = false;
    }
    this.onChange();
  }

  public updateQuantity(n: number): void {
    if (this.quantity === 1 && n === -1) {
      return;
    }
    this.quantity += n;
    this.onChange();
  }

  public onChange(modifierGroup?: ModifierGroup): void {
    if (modifierGroup) {
      modifierGroup.is_valid = true;
    }
    setTimeout(() => {
      this.calculateTotal();
    }, 200);
  }
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  public onClose(event: any): void {
    this.confirm(false);
  }

  public confirm(confirm: boolean): void {
    if (!confirm) {
      this.dialogRef.close();
      return;
    }
    this.submitted = true;

    if (!this.checkQuanitities()) {
      this.snackBar.open(
        this.translateService.instant('COMMON.MISSING_SELECTIONS'),
        this.translateService.instant('CART.GOT_IT'),
        {
          duration: 3500,
          panelClass: 'custom-snackbar'
        }
      );
      return;
    }

    const selectedMods: SelectedModifier[] = this.getSelectedModifiers();
    const itemData =  {
      selectedMods,
      quantity: this.quantity
    };
    this.dialogRef.close(itemData);
  }

  private checkQuanitities(): boolean {
    let isValid = true;
    for (const modifierGroup of this.data.item.modifier_groups) {
      if (modifierGroup.is_valid === false) {
          isValid = false;
          break;
      }
    }
    return isValid;
  }

  private getNumberOfChoices(modifierGroup: ModifierGroup): number {
    let numberOfChoices = 0;
    for (const modKey of Object.keys(this.models[modifierGroup.id])) {
      const modValue: boolean = this.models[modifierGroup.id][modKey];
      if (modValue) {
        numberOfChoices += 1;
      }
    }

    return numberOfChoices;
  }

  private calculateTotal(): void {
    this.total = this.data.item.price;

    for (const key of Object.keys(this.models)) {
      const value: any = this.models[key];

      if (value !== null && typeof value === 'string') {
        this.total += this.findModifierById(value).price;
      } else if (value !== null && typeof value === 'object') {
        if (value.price === 0) {
          return;
        }
        for (const modKey of Object.keys(value)) {
          const modValue = value[modKey];
          if (modValue && typeof modValue === 'boolean') {
            this.total += this.findModifierById(modKey).price;
          } else if (modValue && typeof modValue === 'number') {
            this.total += this.findModifierById(modKey).price * modValue;
          }
        }
      }
    }
    this.total *= this.quantity;
  }

  private findModifierById(id: string): any {
    const allModifiers: any[] = this.flattenModifiers();

    return allModifiers.find((modifier) => modifier.id === id);
  }

  private flattenModifiers(): any[] {
    return this.data.item.modifier_groups
      .reduce((accumulator, modifierGroup) => accumulator.concat(modifierGroup.modifiers), [] as Modifier[]);
  }

  private getSelectedModifiers(): SelectedModifier[] {
    const selectedMods: SelectedModifier[] = [];

    for (const key of Object.keys(this.models)) {
      const value: any = this.models[key];
      if (value !== null && value !== '' && typeof value === 'string') {
        const mod: SelectedModifier = {
          id: value,
          quantity: 1
        };
        selectedMods.push(mod);
      } else if (value !== null && typeof value === 'object') {
        for (const modKey of Object.keys(value)) {
          const modValue: boolean = value[modKey];
          if (modValue && typeof modValue === 'boolean') {
            const mod: SelectedModifier = {
              id: modKey,
              quantity: 1
            };
            selectedMods.push(mod);
          } else if (modValue && typeof modValue === 'number' && modValue > 0) {
            const mod: SelectedModifier = {
              id: modKey,
              quantity: modValue
            };
            selectedMods.push(mod);
          }
        }
      }
    }
    return selectedMods;
  }

  private setIsReadOnly(): void {
    this.readOnly = this.dataService.getRestaurant().type === VenueType.ReadOnly;
  }

}
