import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    standalone: false
})
export class LandingComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.router.navigate(['/menu'], {queryParamsHandling: 'merge'});
  }

}
